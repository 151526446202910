import React from 'react'
import { useState } from 'react'

export default () => {
  const [teams, setTeams] = useState([])
  const staffs = [
    '@bourbon',
    '@tetsuya',
    '@rie',
    '@dai',
    '@mariko',
    '@shigekazu',
    '@shitara',
    '@urabe',
    '@sylvester',
    '@kiyotake',
  ]
  const TEAM_MEMBER = 3

  // generate random number between 0 to (max - 1)
  const randomIdx = max => {
    return Math.floor(Math.random() * max)
  }

  const shuffle = array => {
    const res = array.concat()
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1))
      ;[res[i], res[j]] = [res[j], res[i]]
    }

    return res
  }

  const createTeam = xs => {
    const _xs = shuffle(xs)
    const team_num = Math.floor(xs.length / TEAM_MEMBER)
    const team_num_frimmed = xs.length % TEAM_MEMBER

    let _teams = []
    for (let i = 1; i <= team_num; i++) {
      _teams.push({
        staffs: [],
        seats: i <= team_num_frimmed ? TEAM_MEMBER + 1 : TEAM_MEMBER,
      })
    }

    let currentTeamIndex = 0
    for (let i = 0; i < _xs.length; i++) {
      if (
        _teams[currentTeamIndex].staffs.length ===
        _teams[currentTeamIndex].seats
      ) {
        currentTeamIndex = currentTeamIndex + 1
      }

      _teams[currentTeamIndex].staffs.push(_xs[i])
    }

    return _teams
  }

  const shuffler = xs => () => {
    setTeams(createTeam(xs))
  }

  return (
    <div className="team-maker">
      <div className="row">
        <ul className="staffs">
          {staffs.map(x => (
            <li key={x}>{x}</li>
          ))}
        </ul>
        {teams.length > 0 && (
          <div className="results">
            {teams.map((t, i) => (
              <div key={i}>
                <h5>Unit {i + 1}</h5>
                <p>
                  {t.staffs.map((s, i) => (
                    <span key={s}>
                      <strong>
                        {s}
                        {i < t.staffs.length - 1 && ', '}
                      </strong>
                      <br />
                    </span>
                  ))}
                </p>
              </div>
            ))}
          </div>
        )}
      </div>
      <button onClick={shuffler(staffs)}>Create Unit</button>
    </div>
  )
}
