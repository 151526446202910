import React from 'react'
import { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Layout from './components/layout'
import Routes from './routes'

export default () => {
  const handleTransition = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Router>
      <Layout>
        <Routes onTransition={handleTransition} />
      </Layout>
    </Router>
  )
}
