import React from 'react'
import { useState, useRef, useLayoutEffect } from 'react'

import {
  motion,
  AnimatePresence,
  useViewportScroll,
  useTransform,
  useMotionValue,
} from 'framer-motion'

export default () => {
  const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useViewportScroll()

  const titleParallax = {
    y: useTransform(scrollY, [elementTop, elementTop + 1], [0, -1], {
      clamp: false,
    }),
    opacity: useTransform(scrollY, [0, elementTop + 100], [1, 0]),
    scale: useTransform(scrollY, [0, elementTop + 300], [1, 4]),
  }

  useLayoutEffect(() => {
    setElementTop(120)
  }, [ref])

  return (
    <div className="banner">
      <div className="titles">
        <motion.div
          className="logo"
          initial={{
            opacity: 0,
            scale: 0.95,
          }}
          animate={{
            opacity: 0.8,
            scale: 1,
            transition: {
              duration: 1,
              delay: 1.5,
            },
          }}
        />
        <motion.div
          ref={ref}
          style={titleParallax}
          className="title--main"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            trantition: {
              delay: 2,
              duration: 2,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.5,
            },
          }}
        >
          <AnimatePresence initial={true}>
            {'Grow as a team'.split('').map((c, idx) => (
              <motion.span
                key={idx}
                transition={{
                  delay: 1.5 + 0.05 * idx,
                  duration: 0.3,
                }}
                initial={{
                  opacity: 0,
                  y: 30 + 8 * idx,
                }}
                animate={{
                  opacity: 1,
                  y: 0,
                }}
                exit={{
                  opacity: 0,
                  y: -30,
                }}
              >
                {c !== ' ' ? c : ' '}
              </motion.span>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  )
}
