import React from 'react'
import { useState, useRef, useLayoutEffect } from 'react'

import {
  motion,
  AnimatePresence,
  useViewportScroll,
  useTransform,
} from 'framer-motion'

export default () => {
  const [leftTop, setLeftTop] = useState(0)
  const [rightTop, setRightTop] = useState(0)
  const leftRef = useRef(null)
  const rightRef = useRef(null)
  const { scrollY } = useViewportScroll()

  const leftParallax = {
    y: useTransform(scrollY, [leftTop, leftTop + 1], [0, -0.1], {
      clamp: false,
    }),
  }

  const rightParallax = {
    y: useTransform(scrollY, [rightTop, rightTop + 1], [0, 0.1], {
      clamp: false,
    }),
  }

  useLayoutEffect(() => {
    setLeftTop(leftRef.current.offsetTop + 100)
  }, [leftRef])

  useLayoutEffect(() => {
    setRightTop(rightRef.current.offsetTop + 100)
  }, [rightRef])

  return (
    <div className="objective__item intro">
      <ul>
        <li>プロダクトがいっぱいある</li>
        <li>みんなの目線を合わせる</li>
        <li>ゴールを共有する</li>
      </ul>
      <p>
        10年後を見据えた上での1回目のサミットとして
        <br />
        みんなで話し合って決めます。
      </p>
      <motion.div
        ref={leftRef}
        className="bg-keyboard left"
        style={leftParallax}
      />
      <motion.div
        ref={rightRef}
        className="bg-keyboard right"
        style={rightParallax}
      />
    </div>
  )
}
