import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

export default () => {
  return (
    <motion.div
      id="how-it-works"
      className="scene"
      initial={{
        opacity: 0,
        y: 50,
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 1,
          duration: 1,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 1,
        },
      }}
    >
      <div className="container">
        <div className="objective">
          <div className="objective__item">
            <header>
              <h3>事前にユニットを決めます</h3>
            </header>
            <div className="guide-img guide-1" />
          </div>

          <div className="objective__item">
            <header>
              <h3>
                各ユニットで集まって
                <br />
                議題を話し合う
              </h3>
            </header>
            <p>
              <a
                href="https://crew.tagbangers.co.jp/confluence/pages/viewpage.action?pageId=156140638"
                target="_blank"
              >
                考え方のヒント・テンプレート
              </a>
              を埋めて行きます。
              <br />
              主体的・客観的、ポジティブ・ネガティブに考えてみましょう。
            </p>
            <p>
              <strong>
                ネガティブな発想をしても良いですが、それはチームや会社に関してであり
                <br />
                個人への批判にならないようにしてください。
              </strong>
            </p>
            <div className="guide-img guide-2" />
          </div>

          <div className="objective__item">
            <header>
              <h3>
                時間になったらユニットごとに
                <br />
                みんなの前で発表
              </h3>
            </header>
            <div className="guide-img guide-3" />
          </div>
        </div>
      </div>
    </motion.div>
  )
}
