import React from 'react'

export default () => {
  return (
    <div className="objective__item mission--details">
      <div className="container">
        <div className="descriptions">
          <header>
            <h5>
              ミッション <small>Mission</small>
            </h5>
            <h3>
              世界をクリエイティブにする
              <small>Make the world more creative</small>
            </h3>
          </header>
          <div className="description description--horizontal">
            <h5>
              機能的ベネフィット <small>Functional Benefit</small>
            </h5>
            <ul>
              <li>クオリティの高いシステムを提供できる</li>

              <li>エンタープライズならではの</li>
              <li>困りごとに対応できる</li>

              <li>モダンな開発手法を利用して</li>
              <li>お客さんに新しい価値を提案できる</li>

              <li>機能改変がしやすい</li>
            </ul>
          </div>

          <div className="description description--horizontal">
            <h5>
              情緒的ベネフィット <small>Emotional Benefit</small>
            </h5>
            <ul>
              <li>違和感がない</li>

              <li>手に馴染む</li>

              <li>仕事をするのが楽しい</li>
            </ul>
          </div>

          <div className="description">
            <h5>
              エビデンス <small>Evidence</small>
            </h5>
            <ul>
              <li>10年間エンタプライズ向け基幹システム開発をしてきた</li>

              <li>
                新サービスや新技術を積極的に取り入れ開発手法を改善してきた
              </li>

              <li>
                R&D / 新規開発に関する開発手法のコンサル・提案が徐々に増えてきた
              </li>

              <li>
                インバウンドのみの営業 /
                口コミによる案件取得、リピーター率の高さ
              </li>

              <li>アウトプット頻度とその影響度が高い(今後の課題)</li>
            </ul>
          </div>
        </div>
        <figure>
          <img src={require('../../images/missions.png')} alt="mission" />
        </figure>
      </div>
    </div>
  )
}
