import React from 'react'
import { motion } from 'framer-motion'

import TeamMaker from '../components/team-maker'

export default () => {
  return (
    <motion.div
      id="time-table"
      className="scene"
      initial={{
        opacity: 0,
        y: 50,
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          delay: 1,
          duration: 1,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 1,
        },
      }}
    >
      <div className="container">
        <div className="grid">
          <div className="description">
            <h1>TIME TABLE</h1>
            <p>
              所属チームに関係なくランダムに3つのチームを作り
              <br />
              1つのお題に対して1時間単位でみんなで話し合います。
            </p>
            <ul>
              <li>40分：ディスカッション</li>
              <li>15分：各チーム5分の持ち時間で発表する</li>
              <li>5分：休憩して次のお題へ</li>
            </ul>

            <p>
              <a
                href="https://crew.tagbangers.co.jp/confluence/pages/viewpage.action?pageId=156140638"
                target="_blank"
              >
                ディスカッションテンプレート
              </a>
            </p>
            <div className="team-maker-container">
              <TeamMaker />
            </div>
          </div>
          <div className="agenda">
            <div className="item">
              <div className="num">01.</div>
              <h3 className="session__title">はじめに</h3>
              <div className="session__list">
                <h4>Mission / Vision / Valueについて</h4>
                <h4>事実: これまでのタグバンのあゆみ</h4>
                <ul>
                  <li>売上金額</li>
                  <li>顧客推移</li>
                  <li>引き合いの傾向の推移</li>
                </ul>
                <h4>アクション: これからのマイルストーン</h4>
                <h4>
                  アクション: 今年のテーマ 「チームとして成長する / 評価される」
                </h4>
              </div>
            </div>
            <div className="item">
              <div className="num">02.</div>
              <h3 className="session__title">タグバンガーズの今を考える</h3>
              <h4 className="session__subtitle">ファクトの洗い出し</h4>
              <div className="session__list">
                <ul>
                  <li>会社の強み・弱みの認識を共有する</li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="num">03.</div>
              <h3 className="session__title">アーティストって？チームって？</h3>
              <h4 className="session__subtitle">タグバンユビキタス</h4>
              <div className="session__list">
                <h4>Tagbangersにとって</h4>
                <ul>
                  <li>アーティストとはどんな存在か / どのように行動するか</li>
                  <li>チームとはどんな存在か</li>
                  <li>
                    (お客さんの)カルチャーを支えるアプリケーションを作るには(作品に対して求めるもの、対顧客向き)
                  </li>
                </ul>
                <p>
                  上記事実の洗い出しと共有できた認識をもとに、4〜7のアクションプランを考える
                </p>
              </div>
            </div>
            <div className="item">
              <div className="num">04.</div>
              <h3 className="session__title">
                高い技術力を証明し周りから認めてもらう
              </h3>
              <h4 className="session__subtitle">外向き</h4>
              <div className="session__list">
                <p>
                  チームとして成長しているかを評価されるにはどうすれば良いか
                </p>
              </div>
            </div>
            <div className="item">
              <div className="num">05.</div>
              <h3 className="session__title">
                毎日 "ありがとう" が聞こえるチームになる
              </h3>
              <h4 className="session__subtitle">内向き</h4>
              <div className="session__list">
                <ul>
                  <li>
                    チームとして一緒に働くメンバーとはどんな関係でありたいか
                  </li>
                  <li>そのためにどんなことができるか</li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="num">06.</div>
              <h3 className="session__title">
                最高のフィードバックから最高のアウトプットを作るために
              </h3>
              <h4 className="session__subtitle">
                DevOps、チーム間連携・ロール
              </h4>
              <div className="session__list">
                <ul>
                  <li>最高のフィードバックをどんな形で取得するか</li>
                  <li>チームでどのように連携していくか</li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="num">07.</div>
              <h3 className="session__title">プロダクトつくろーぜ！</h3>
              <h4 className="session__subtitle">
                オリジナルブランド、オーディエンス向き
              </h4>
              <div className="session__list">
                <ul>
                  <li>
                    tagbangers.design
                    に載っていくような活動・デザイン・プロダクトの案を出す
                  </li>
                  <li>
                    自社プロダクトを作ろう
                    <ul>
                      <li>案件じゃないチーム連携を生み出せる</li>
                    </ul>
                  </li>
                  <li>
                    作りたいアプリをみんなで出す
                    <ul>
                      <li>ショーケースを増やす</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="num">08.</div>
              <h4 className="session__title">2020 のリリースノート</h4>
              <h4 className="session__subtitle">全社</h4>
              <div className="session__list">
                <h4>4〜7を踏まえて集まった意見をもとに全社の目標を決めよう</h4>
                <ul>
                  <li>技術</li>
                  <li>対顧客</li>
                  <li>チーム連携</li>
                  <li>自社プロダクト</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
