import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

export default ({ children }) => {
  const { pathname } = useLocation()

  return (
    <>
      <nav className="global">
        <div className="container">
          <Link to="/" className="logo">
            Tagbangers summit 2020
          </Link>
          <div>
            <Link
              to="/how-it-works"
              className={pathname === '/how-it-works' ? 'current' : ''}
            >
              how it works
            </Link>
            <Link
              to="/timetable"
              className={pathname === '/timetable' ? 'current' : ''}
            >
              time table
            </Link>
          </div>
        </div>
      </nav>
      <main>{children}</main>
    </>
  )
}
