import React from 'react'
import { Link } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

import Banner from './banner'
import Intro from './intro'
import Mission from './mission'

export default () => {
  return (
    <motion.div
      initial={{
        opacity: 1,
      }}
      animate={{
        opacity: 1,
        transition: {
          delay: 1.5,
          duration: 1,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 1,
        },
      }}
    >
      <div id="cover" className="scene">
        <Banner />
      </div>
      <div id="about" className="scene">
        <div className="container">
          <h2 className="section-title">About Tagbangers Summit</h2>
        </div>
        <div className="objective">
          <Intro />
          <div className="objective__item mission">
            <div className="container">
              <div className="tagline">
                <h4>MISSION</h4>
                <h3>世界をクリエイティブにする</h3>
              </div>
              <div className="descriptions">
                <p>
                  <strong>
                    本当に必要なことを把握し、シンプルに実装する。
                  </strong>
                  <br />
                  複雑な業務の中で「こうだったらいいな」を実現することに飽き足らず、システムによってユーザの考えにさらにクリエイティビティをもたらせる存在であること。
                  <br />
                  インターネットによって物理的なボーダーを越え、皆様とともに、
                  新しい価値や文化、スタイルを作り出せる存在になること。
                  <br />
                  <br />
                  それがタグバンガーズが大事にしていることです。
                </p>
              </div>
            </div>
          </div>
          <Mission />
          <div className="objective__item">
            <div className="container">
              <div className="tagline">
                <h4>VISION</h4>
                <h3>アーティストであれ</h3>
              </div>
              <div className="descriptions">
                <p>
                  <strong>文化を創っていく存在になるには</strong>
                  、そのために必要な良いツールに加え、
                  <br />
                  良いものを見極めるセンスや共感力が必要です。
                  <br />
                  オーディエンスや状況を理解した上で、表現したものにより影響を与えられる、アーティストでありたいと考えています。
                </p>
              </div>
            </div>
          </div>
          <div className="objective__item">
            <div className="container">
              <div className="tagline">
                <h3>VALUES</h3>
              </div>
              <div className="descriptions">
                <p>
                  <strong>本質を見極めシンプルに実現するセンスを持つ</strong>
                </p>
                <p>
                  <strong>いいツールを知り、研鑽できる</strong>
                </p>
                <p>
                  <strong>コラボやセッションを大事にする</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="next__item">
            <div className="container">
              <h5>Next</h5>
              <a href="/how-it-works">How it works</a>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
