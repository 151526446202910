import React from 'react'
import { useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'

import Cover from './scenes/cover/index'
import HowItWorks from './scenes/how-it-works'
import TimeTable from './scenes/timetable'

export default ({ onTransition }) => {
  const location = useLocation()
  const [_, rootPath] = location.pathname.split('/')

  const history = useHistory()

  return (
    <AnimatePresence initial={true} onExitComplete={onTransition}>
      <Switch location={location} key={'/' + rootPath}>
        <Route exact path="/" component={Cover} />
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/timetable" component={TimeTable} />
      </Switch>
    </AnimatePresence>
  )
}
